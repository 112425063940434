/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef, useState } from 'react';
import styles from './Navbar.module.scss';
import Link from 'next/link';
import { MenuItem, MenuItemToMenuItemConnection } from 'generated-graphql';
import Arrow from 'public/svgs/arrow-head.svg';
import { useRouter } from 'next/router';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import gsap from 'gsap/dist/gsap';
import Image from 'next/image';
gsap.registerPlugin(ScrollTrigger);

export interface NavBarProps {
  menuItems: any[];
}

const Navbar: React.FC<NavBarProps> = ({ menuItems }) => {
  const [showDropdown, setShowDropDown] = useState<string>();
  const [mobileOpened, setMobileOpened] = useState(false);
  const navbarRef = useRef<HTMLFormElement | null>(null);

  const router = useRouter();



  const renderDropdown = (items: MenuItemToMenuItemConnection[]) => {
    return (
      <ul className={`${styles.dropdown}`}>
        {items?.map((item: any) => (
          <li key={item.id}>
            <a href={item.url} onClick={() => setMobileOpened(false)}>
              <div>{item.label}</div>
              {item.description && <span>{item.description}</span>}
            </a>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <nav
      ref={navbarRef}
      className={`navbar navbar-expand-md navbar-light w-100 ${styles.navbar}`}
    >
      <div className="container ">
        <div className='row'>
          <div className='col-12'>
            <div className="d-flex w-100 d-flex h-100 align-items-center h-100">
              <a href="/" style={{ width: 'fit-content', paddingRight: "20px" }}>
                <Image src="/svgs/A3G-logos.svg" alt="Agent3Group Logo" width={204} height={44} quality={100} />
              </a>

              <div
                onClick={() => {
                  setMobileOpened(!mobileOpened)

                  ScrollTrigger.refresh();
                }}
                className={`${styles.menuToggler} ${mobileOpened ? styles.close : ''
                  } d-lg-none`}
              >
                <span className={styles.line}></span>
                <span className={styles.line}></span>
                <span className={styles.line}></span>
              </div>
              <div
                className={`${mobileOpened ? styles.open : ''} ${styles.menuWrapper
                  }  navbar-collapse`}
              >
                <ul className={` ${styles.navMenu}`}>
                  {menuItems &&
                    menuItems?.map((item) => {
                      const isDocuments = item.label === '[documents]';
                      const isContact = item.label === 'Contact us';
                      return (
                        <li
                          key={item.id}
                          className={`${styles.dropdownItem} ${item.cssClasses.length ? styles[item.cssClasses[0]] : ''
                            } ${showDropdown === item.id ? styles.openedDropdown : ''} ${!isDocuments && styles.menuItem
                            } ${isContact ? styles.contactBtn : ''} `}
                        >
                          {!isDocuments && item.childItems?.nodes.length && item.url ? (

                            <a
                              href={item.url}
                              role="button"
                              className={`${styles.dropdownTrigger} d-flex justify-content-between`}
                              onClick={() => setShowDropDown((prev) => prev === item.id ? '' : item.id)}
                            >
                              {item.label}
                              <span className={styles.arrow}>
                                <Arrow />
                              </span>
                            </a>
                          ) : null}
                          {!isDocuments && item.childItems?.nodes.length && !item.url ? (
                            <p className={`${styles.dropdownTrigger} d-flex justify-content-between`}
                              onClick={() => setShowDropDown((prev) => prev === item.id ? '' : item.id)}>
                              {item.label}
                              <span className={styles.arrow}>
                                <Arrow />
                              </span>
                            </p>
                          ) : null

                          }
                          {!isDocuments && !item.childItems.nodes.length ? (
                            <a className={isContact ? "cta nav-btn" : ""} href={item.url || '#'} onClick={() => setMobileOpened(false)}>{item.label}</a>
                          ) : null}
                          {!isDocuments && item.childItems?.nodes.length
                            ? renderDropdown(item.childItems.nodes)
                            : null}
                          {isDocuments && (
                            <div
                              className={`${styles.docs} d-lg-none row py-3`}
                            // style={{ borderTop: '1px solid #f2f4f7' }}
                            >
                              {item?.childItems?.nodes?.map((doc: MenuItem, idx: number) => (
                                <a href={doc.url || ''} key={doc.id} onClick={() => setMobileOpened(false)}>{doc.label}</a>

                              ))}
                            </div>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </nav >
  );
};
export default Navbar;
