import { gql } from '@apollo/client';

function CoreCode(props: any) {

  return (<div><h1>CODEEEEEEE</h1>{JSON.stringify(props)}<div dangerouslySetInnerHTML={{ __html: props.renderedHtml.replaceAll('<pre class=\"wp-block-code\"><code>', '').replaceAll('</code></pre>', '').replaceAll('\n', '').replaceAll('&lt;', '<') || '' }} /></div>);
}

CoreCode.fragments = {
  entry: gql`
    fragment CoreCodeFragment on CoreCode {
      renderedHtml
    }
  `,
  key: `CoreCodeFragment`,
};

CoreCode.displayName = 'CoreCode';

export default CoreCode;
