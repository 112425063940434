import CoreParagraph from './CoreParagraph';
import CoreEmbed from './CoreEmbed';
import CoreMediaText from './CoreMediaText';
import CoreHeading from './CoreHeading';
import CoreButtons from './CoreButtons';
import CoreButton from './CoreButton';
import CoreColumns from './CoreColumns';
import CoreColumn from './CoreColumn';
import CoreImage from './CoreImage';
import CoreCover from './CoreCover';
import CoreList from './CoreList';
import CoreQuote from './CoreQuote';
import CoreSeparator from './CoreSeparator';
import CoreVideo from './CoreVideo';
import A3BlocksA3Section from './A3BlocksA3Section';
import CoreTable from './CoreTable';
import CoreCode from './CoreCode';

export default {
  CoreParagraph,
  CoreEmbed,
  CoreMediaText,
  CoreHeading,
  CoreButton,
  CoreButtons,
  CoreColumns,
  CoreColumn,
  CoreList,
  CoreQuote,
  CoreImage,
  CoreCover,
  CoreSeparator,
  CoreVideo,
  A3BlocksA3Section,
  CoreTable,
  CoreCode,
};
