import blocks from '@/wp-blocks';
import { DocumentNode, print } from 'graphql';
import gql from 'graphql-tag';
// import { ServiceIdType, PageIdType, ResourceIdType } from 'generated-graphql';
import { CORE_PAGE_FIELDS } from 'gql/pages';
import { HEADER_FRAGMENT } from './header';
import { FOOTER_FRAGMENT } from './footer';

// const idTypes = { ServiceIdType, PageIdType, ResourceIdType } as any;

export const allEntries = `
${print(blocks.A3BlocksA3Section.fragments.entry)}
${print(blocks.CoreParagraph.fragments.entry)}
${print(blocks.CoreEmbed.fragments.entry)}
${print(blocks.CoreMediaText.fragments.entry)}
${print(blocks.CoreHeading.fragments.entry)}
${print(blocks.CoreButton.fragments.entry)}
${print(blocks.CoreButtons.fragments.entry)}
${print(blocks.CoreColumn.fragments.entry)}
${print(blocks.CoreColumns.fragments.entry)}
${print(blocks.CoreImage.fragments.entry)}
${print(blocks.CoreCover.fragments.entry)}
${print(blocks.CoreList.fragments.entry)}
${print(blocks.CoreQuote.fragments.entry)}
${print(blocks.CoreVideo.fragments.entry)}
${print(blocks.CoreSeparator.fragments.entry)}
${print(blocks.CoreTable.fragments.entry)}
${print(blocks.CoreCode.fragments.entry)}
`;

export const allKeys = `
...${blocks.A3BlocksA3Section.fragments.key}
...${blocks.CoreParagraph.fragments.key}
...${blocks.CoreEmbed.fragments.key}
...${blocks.CoreMediaText.fragments.key}
...${blocks.CoreHeading.fragments.key}
...${blocks.CoreButton.fragments.key}
...${blocks.CoreButtons.fragments.key}
...${blocks.CoreColumn.fragments.key}
...${blocks.CoreColumns.fragments.key}
...${blocks.CoreImage.fragments.key}
...${blocks.CoreCover.fragments.key}
...${blocks.CoreList.fragments.key}
...${blocks.CoreQuote.fragments.key}
...${blocks.CoreVideo.fragments.key}
...${blocks.CoreSeparator.fragments.key}
...${blocks.CoreTable.fragments.key}
...${blocks.CoreCode.fragments.key}
`;

function getGqlString(doc: DocumentNode) {
  return doc.loc && doc.loc.source.body;
}

export const getEntityBlocks = (
  entity: string = 'page',
  coreFragments: any = CORE_PAGE_FIELDS,
  coreFragmentKey: string = 'CorePageFields',
) => {
  const coreFragmentsDefault = coreFragments;
  const coreFragmentKeyDefault = `...${coreFragmentKey}`;

  const idTypeKey = entity.charAt(0).toUpperCase() + entity.slice(1) + `IdType`;

  const frag = gql`
  ${allEntries}
  ${coreFragmentsDefault}
  query ${entity}(
    $id: ID!
    $idType: ${idTypeKey}
    $asPreview: Boolean
  ) {
      ${entity}(id: $id, idType: $idType, asPreview: $asPreview) {
      ${coreFragmentKeyDefault}
      editorBlocks(flat:false) {
        __typename
        
        ${allKeys}
        
        ... on CoreShortcode {
          renderedHtml
        }
        innerBlocks {
          __typename
          ${allKeys}
          innerBlocks {
            __typename
            ${allKeys}
            innerBlocks {
              __typename
              ${allKeys}
          innerBlocks {
              __typename
              ${allKeys}
              innerBlocks {
              __typename
              ${allKeys}
              innerBlocks {
              __typename
              ${allKeys}
              innerBlocks {
              __typename
              ${allKeys}
        
            }
            }
            }
            }
            }
          }
        }
      }
    }
  }
  `;

  // console.log(getGqlString(frag));
  return frag;
};

export const getEntityBlocks2 = (entity: string = 'page') => {
  const idTypeKey = entity.charAt(0).toUpperCase() + entity.slice(1) + `IdType`;

  return gql`
    ${allEntries}
    
    query ${entity}(
      $id: ID!
      $idType: ${idTypeKey}
      $asPreview: Boolean
    ) {
        ${entity}(id: $id, idType: $idType, asPreview: $asPreview) {
        editorBlocks(flat:false) {
          __typename
          ${allKeys}
          ... on CoreShortcode {
            renderedHtml
          }
          innerBlocks {
            __typename
            ${allKeys}
            innerBlocks {
              __typename
              ${allKeys}
              innerBlocks {
                __typename
                ${allKeys}
                innerBlocks {
                  __typename
                  ${allKeys}
                  innerBlocks {
                    __typename
                    ${allKeys}
                    innerBlocks {
                    __typename
                    ${allKeys}
                    
                  }
                    
                  }
                }
              }
            }
          }
        }
      }
     
    }
    `;
};

export const getEntity = (
  entity: string = 'page',
  coreFragments: any = CORE_PAGE_FIELDS,
  coreFragmentKey: string = 'CorePageFields',
) => {
  const coreFragmentsDefault = coreFragments;
  const coreFragmentKeyDefault = `...${coreFragmentKey}`;

  // const idTypeKey = entity.charAt(0).toUpperCase() + entity.slice(1) + `IdType`;

  return gql`
    ${allEntries}
    ${coreFragmentsDefault}
    ${HEADER_FRAGMENT.entry}
    ${FOOTER_FRAGMENT.entry}
    query ${entity}(
      $databaseId: ID!
    ) {
        ${entity}(id: $databaseId, idType: DATABASE_ID) {
        ${coreFragmentKeyDefault}
        editorBlocks(flat:false) {
          __typename
          
          ${allKeys}
          
          ... on CoreShortcode {
            renderedHtml
          }
          innerBlocks {
            __typename
            ${allKeys}
            innerBlocks {
              __typename
              ${allKeys}
              innerBlocks {
                __typename
                ${allKeys}
                innerBlocks {
                  __typename
                  ${allKeys}
                  innerBlocks {
                    __typename
                    ${allKeys}
                    innerBlocks {
                      __typename
                      ${allKeys}
                      innerBlocks {
                        __typename
                        ${allKeys}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      ...${HEADER_FRAGMENT.key}
      ...${FOOTER_FRAGMENT.key}
     
    }
    `;
};
